.fadeIn {
    animation: fadeInAnimation ease-in 1.0s;
}
  
@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
     }
}